import { Component, OnInit, computed, effect, inject, signal } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AsdMenuComponent } from './asd-menu/asd-menu.component';
import { AuthenticationService } from '../services/authentication.service';
import { LoggedUser } from '../models/user.model';
import { JsonPipe } from '@angular/common';
import { ASD } from './model/asd.model';
import { AsdService } from './service/asd.service';
import { tap } from 'rxjs';
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'app-asd',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    AsdMenuComponent,
    JsonPipe
  ],
  templateUrl: './asd.component.html',
  styleUrl: './asd.component.scss'
})
export default class AsdComponent implements OnInit{
  authService = inject(AuthenticationService);
  asdService = inject(AsdService);
  loggedUser = signal<LoggedUser | null>(null);
  asd = signal<any>([]);
  points = computed(() => this.asdService.asdPoints());
  loggedAsd = computed(() => this.asdService.loggedASD());
  constructor() {
    effect(async () => {
      (await this.authService.getUser().then(loggedUser => this.loggedUser.update(loggedUser)));
    });
  }
  
  async ngOnInit(): Promise<void> {
    this.points = computed(() => this.asdService.asdPoints());
    this.loggedAsd = computed(() => this.asdService.loggedASD());
    await this.authService.getUser().then(loggedUser => this.loggedUser = loggedUser);
    const id = this.loggedUser()?.representativeEditionId;
    if(id)
    this.asdService.getAsd(id).pipe(
      tap(async res => {
        if(!res) return;
        this.asd.set(res.data[0]);
        this.asdService.setASD(res.data[0]);
        if(res.data[0].representativeEditionId)
        await Preferences.set({key: 'points', value: res.data[0].points })
      })
    ).subscribe();
    
  }
  
}
