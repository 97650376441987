<!-- @todo aggiungere logica per non mostrare il caricamento bollini su desktop a chi non è loggato come ASD -->
@if(loggedAsd() !== undefined) {
    <div class="my-10 mb-72">
        <h1 class="hidden md:block text-5xl font-bold text-orange my-6">
           <img src="./assets/pallone.png" class="inline" alt=""> Area ASD / SSD
        </h1>
        <div class="h-full px-3 py-4 overflow-y-auto ">
            <app-asd-menu></app-asd-menu>
        </div>
        <div class="p-4">
            <div class="grid grid-cols-2 grid-flow-col h-full gap-4 content-center">
                <div class="col-span-2">
                    <p>
                        <span class="text-lg sm:text-2xl block">{{loggedAsd().clubName}}</span>
                        <span class="text-base sm:text-lg block">{{loggedAsd().municipality}}</span>
                        <span class="text-sm sm:text-base">{{loggedAsd().mail}}</span>
                    </p>
                </div>
                <div class="my-4 align-middle text-right">
                    <span class="bg-green text-white px-3 py-2 rounded-md text-2xl">{{points()}}</span>
                    <br><span class="block text-xs font-bold mt-4">Saldo Buoni Sport</span>
                </div>
            </div>
            <div class="mt-4">
                <p>
                    Le Associazioni e Società Sportive Dilettantistiche potranno richiedere  
                    gratuitamente i premi dal Catalogo <b>entro il 15 ottobre 2024</b>.
                </p>
            </div>
            <router-outlet />
        </div>

    </div>
}
    





