import { Component, Input, OnInit, computed, inject, signal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { LoggedUser } from '../../models/user.model';
import { CartService } from '../../orders/services/cart.service'; 
import { environment as env } from '../../../environments/environment';
import { Dialog } from '@capacitor/dialog';

@Component({
  selector: 'app-asd-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive],
  templateUrl: './asd-menu.component.html',
  styleUrl: '../asd.component.scss'
})
export class AsdMenuComponent implements OnInit {
  cartService = inject(CartService);
  authenticationService = inject(AuthenticationService);
  loggedUser = signal<LoggedUser| null>(null);
  canOrder = env.orders;
  async wishList() {
    
  }
 
  async ngOnInit(): Promise<void> {
    await this.authenticationService.getUser().then(loggedUserRes => this.loggedUser = loggedUserRes);
    await this.cartService.refreshCart();
  }

  

  async logout(){
    await this.authenticationService.logout();
  }
  
  async logOutAlert() {
    const { value } = await Dialog.confirm({
      title: 'Vuoi eseguire il Logout?',
      message: `Confermi l'uscita?`
    });

    if (value) {
      this.logout();
    }
  }
}
