<div>
  <ul class="hidden sm:menu sm:menu-horizontal ">
    <li><a class="text-neutral" routerLinkActive="active-link" [routerLink]="['carica-buoni-sport']">Carica Buoni Sport</a></li>
    <li><a class="text-neutral" routerLinkActive="active-link" [routerLink]="['marketing-kit']">Marketing Kit</a></li>
    <li><a class="text-neutral" routerLinkActive="active-link" [routerLink]="['lista-dei-desideri', loggedUser()?.representativeEditionId]">Lista dei desideri</a></li>
    <li><a class="text-neutral" routerLinkActive="active-link" [routerLink]="['modifica-asd', loggedUser()?.representativeEditionId]">Modifica il tuo profilo</a></li>
    <li><a class="text-neutral" routerLinkActive="active-link" [routerLink]="['carica-codice-invito']">Carica codice invito</a></li>
    @if(canOrder) {
      <li><a class="text-neutral" routerLinkActive="active-link" [routerLink]="['ordini']">Ordini</a></li>
    }
    <div>
      <li>
        <a class="text-neutral " (click)="logOutAlert()">
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="#696969" 
          stroke-width="1.5" 
          stroke-linecap="round" 
          stroke-linejoin="round">
          <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
        </svg> 
        Esci
      </a>
      </li>
    </div>
  </ul>
</div>

